<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus"/>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'inquiriesIndex',
  components: {
    PageHeaderLayout
  },
  data() {
    return {
      menus: [
        {
          icon: 'baoming',
          display_name: '普通询单',
          url: '/enrollIndex/list',
          permission_name: 'inquiry_apply_manage'
        },
        //   /charging_standard/save
        {
          icon: 'woxundan',
          display_name: '年框大全案',
          url: '/enrollIndex/yearEnroll',
          permission_name: 'inquiry_apply_manage'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
